<template>
    <!-- 输入式 日期时间输入框 -->
    <div class="dateTime">
        <input type="text" v-model="dateTime" @blur="handleBlur" @focus="storeOriginalValue"
            :placeholder="dateTime?'':$t('common.timePH')" />
    </div>
</template>

<script>
    import timeFormat from '@/utils/methApi/timeFormat'
    export default {
        props: ['paramTime'],
        data() {
            return {
                dateTime: this.paramTime ? timeFormat(Number(this.paramTime), 'dateTime') : '',
                originalValue: '',// 获取焦点时的原始值
            };
        },
        methods: {
            // 临时存储获取焦点时的原始值
            storeOriginalValue() {
                this.originalValue = this.dateTime;
            },
            // 失去焦点时验证时间格式
            handleBlur() {
                var dateTime = this.dateTime.replace('：', ':').replace('/', '-')
                
                const formattedDateTime = this.formatDateTime(dateTime);
                const dateTimePattern = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/;
                if (!formattedDateTime) {
                    console.log('日期时间格式化出错（可忽略）','this.dateTime：',this.dateTime,'，dateTime：',dateTime,'，formattedDateTime：',formattedDateTime)
                    return
                } if (!dateTimePattern.test(formattedDateTime)) {
                    console.log('日期时间格式验证不通过','formattedDateTime：',formattedDateTime)
                    this.resetDateTimeWithMessage(this.$t('tip.timeFormatErr'));
                } else {
                    // Additional validation can be added here if needed
                    // For example, checking if the date is valid
                    this.dateTime = formattedDateTime
                    const date = new Date(formattedDateTime.replace(' ', 'T'));

                    if (isNaN(date.getTime())) {
                        console.log('时间戳获取出错','formattedDateTime：',formattedDateTime,'，date：',date,'，date.getTime()：',date.getTime())
                        this.resetDateTimeWithMessage(this.$t('tip.timeFormatErr2'));
                    } else {
                        this.$emit('update:paramTime', date.getTime())
                    }
                }
            },
            // 重置为原数据并给出提示
            resetDateTimeWithMessage(message) {
                this.dateTime = this.originalValue;
                this.$message({
                    message: message,
                    type: 'error',
                    center: true,
                    customClass: 'shotMsg',
                });
            },
            // 补全月日时分秒的0
            formatDateTime(dateTime) {
                const parts = dateTime.split(' ');
                if (parts.length !== 2) {
                    console.log('日期时间空格间隔出错','dateTime：',dateTime,'，parts：',parts)
                    return dateTime;
                }

                const dateParts = parts[0].split('-');
                const timeParts = parts[1].split(':');

                if (dateParts.length !== 3 || timeParts.length !== 3) {
                    console.log('日期间隔（-）出错','parts[0]：',parts[0],'，dateParts：',dateParts)
                    return dateTime;
                }
                if (dateParts.length !== 3 || timeParts.length !== 3) {
                    console.log('时间间隔（:）出错','parts[1]：',parts[1],'，timeParts：',timeParts)
                    return dateTime;
                }

                const [year, month, day] = dateParts;
                const [hour, minute, second] = timeParts;

                const formattedDate = [
                    year,
                    month.padStart(2, '0'),
                    day.padStart(2, '0')
                ].join('-');

                const formattedTime = [
                    hour.padStart(2, '0'),
                    minute.padStart(2, '0'),
                    second.padStart(2, '0')
                ].join(':');

                return `${formattedDate} ${formattedTime}`;
            }
        },
        watch: {
            paramTime(newVal) {
                if (newVal) {
                    this.dateTime = timeFormat(Number(newVal), 'dateTime');
                }
            }
        },
    };
</script>

<style scoped lang="stylus">
    .dateTime {
        line-height: 32px;
        width: 100%;

        input {
            width: 100%;
            padding: 0 10px;
            border: 1px solid var(--border2);
            border-radius: 2px;

            &:focus,
            &:hover {
                border-color: var(--active);
            }
        }
    }
</style>